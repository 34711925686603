<template>
  <b-form>
    <b-row>
      <b-col cols="12" md="6" lg="4">
        <h3 class="mr-2">Dépenses Administratives :</h3>
        <span>{{
          amountToCurrency(projectData.inventory.total_admin_expenses)
        }}</span>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <h3 class="mr-2">Vendu HT :</h3>
        <span>{{ amountToCurrency(projectData.inventory.total_pretax) }}</span>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <h3 class="mr-2">Etat de Paiement :</h3>
        <span>{{ amountToCurrency(projectData.inventory.total_received) }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4">
        <b-button
          v-if="!isLost && !isWon && !isClosed"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="warning"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="toggleAsLost"
        >
          <span
            ><feather-icon class="mr-1" size="16" icon="Trash2Icon" />Définir
            Comme Perdu</span
          >
        </b-button>
        <b-button
          v-if="isWon && !isClosed"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="closeProject"
        >
          <span
            ><feather-icon class="mr-1" size="16" icon="SlashIcon" />Fermer le
            Projet</span
          >
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { amountToCurrency } from "@/utils/formatAmount";
import { toRefs } from "@vue/composition-api";
import useProjectDetails from "../projects-view/useProjectDetails";

export default {
  name: "ProjectEditTabState",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: ["projectData"],
  setup(props, { emit }) {
    const {
      toggleAsLost,
      closeProject,
      hasProjectExpenses,
      isLost,
      isWon,
      isClosed,
    } = useProjectDetails(toRefs(props), emit)
    return {
      toggleAsLost,
      closeProject,
      amountToCurrency,
      hasProjectExpenses,
      isLost,
      isWon,
      isClosed
    };
  },
};
</script>

<style></style>
