<template>
  <div>
    <!-- Header: Project manager -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Administrateurs du Projet</h4>
    </div>

    <!-- Form: Project adminitrator Form -->
    <b-form @submit.prevent="onSubmit" @reset.prevent="resetForm" class="mt-1">
      <b-row>
        <!-- Field: project manager -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Responsable" label-for="assignee">
            <v-select
              v-model="projectLocal.assignee_iid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="assignees"
              :reduce="(val) => val.uid"
              :clearable="false"
              input-id="assignee"
              label="name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: deputy project manager -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Adjoint" label-for="deputy">
            <v-select
              v-model="projectLocal.deputy_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="deputies"
              :reduce="(val) => val.uid"
              :clearable="false"
              input-id="deputy"
              label="name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: project techniician -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Technicien" label-for="technician">
            <v-select
              v-model="projectLocal.technician_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="technicians"
              :reduce="(val) => val.uid"
              :clearable="false"
              input-id="technician"
              label="name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: project exert -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Expert" label-for="expert">
            <v-select
              v-model="projectLocal.expert_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="experts"
              :reduce="(val) => val.uid"
              :clearable="false"
              input-id="expert"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            :disabled="isLoading"
          >
          <b-spinner v-if="isLoading" small></b-spinner>
            Sauvegarder
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="() => router.go(-1)"
          >
            Retour
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BSpinner,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import { useRouter } from '@core/utils/utils'

export default {
  name: "ProjectEditAssignee",
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BSpinner,
    // flatPickr,
    // BFormInput,
    vSelect,
    // BFormRadioGroup,
    // BFormCheckboxGroup,
    BButton,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter();
    const isLoading = ref(false)
    const assignees = ref([]);
    const deputies = ref([]);
    const technicians = ref([]);
    const experts = ref([]);

    //get assignees list
    const fetchAssignees = () => {
      store.dispatch("user/allUsers", {role: 'responsable_projet'}).then(users => {
        assignees.value = users;
      });
    };

    //get deputies list
    const fetchDeputies = () => {
      store.dispatch("user/allUsers", {role: 'adjoint_projet'}).then(users => {
        deputies.value = users;
      });
    };

    //get technicians list
    const fetchTechnicians = () => {
      store.dispatch("user/allUsers", {role: 'technicien_projet'}).then(users => {
        technicians.value = users;
      });
    };

    //get experts list
    const fetchExperts = () => {
      store.dispatch("user/allUsers", {role: 'expert_projet'}).then(users => {
        experts.value = users;
      });
    };

    const projectLocal = ref(JSON.parse(JSON.stringify(props.projectData)));

    const resetForm = () => {
      projectLocal.value = JSON.parse(JSON.stringify(props.projectData));
    };

    watch(
      props.projectData,
      () => {
        resetForm(), fetchAssignees(), fetchDeputies(), fetchTechnicians(), fetchExperts();
        resetForm(), fetchAssignees(), fetchDeputies(), fetchTechnicians(), fetchExperts();
        resetForm(), fetchAssignees(), fetchDeputies(), fetchTechnicians(), fetchExperts();
        resetForm(), fetchAssignees(), fetchDeputies(), fetchTechnicians(), fetchExperts();
      },
      { deep: true }
    );

    watch(
      projectLocal,
      (newProjectData) => {
        if (
          newProjectData.hasOwnProperty("customer") &&
          typeof newProjectData.customer === "object"
        )
          newProjectData.customer = newProjectData.customer?.name;
      },
      { deep: true, immediate: true }
    );

    const onSubmit = () => {
      isLoading.value = true
      store
        .dispatch("project/updateProject", projectLocal.value)
        .then((response) => {
          projectLocal.value = response.resource;
          isLoading.value = false
          router.go(-1);
        })
        .catch(error => isLoading.value = false);
    };

    fetchAssignees();
    fetchDeputies();
    fetchTechnicians();
    fetchExperts();

    return {
      isLoading,
      projectLocal,
      assignees,
      deputies,
      technicians,
      experts,
      resetForm,
      onSubmit,
      router,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
