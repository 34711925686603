<template>
  <component :is="projectData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données du Projet</h4>
      <div class="alert-body">
        Pas de projet trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'projects-list' }">
          la liste des Projets
        </b-link>
        pour d'autres Projets.
      </div>
    </b-alert>

    <b-tabs v-if="projectData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Générale</span>
        </template>
        <project-edit-tab-account :project-data="projectData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Avancée</span>
        </template>
        <project-edit-tab-information :project-data="projectData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: State -->
      <b-tab>
        <template #title>
          <feather-icon icon="AlertTriangleIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">États</span>
        </template>
        <project-edit-tab-state :project-data="projectData" @stateUpdated="getProject" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import ProjectEditTabAccount from "./ProjectEditTabAccount.vue";
import ProjectEditTabInformation from "./ProjectEditTabInformation.vue";
import ProjectEditTabState from "./ProjectEditTabState.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ProjectEditTabAccount,
    ProjectEditTabInformation,
    ProjectEditTabState,
  },
  setup() {
    const projectData = ref(null);
    
    const getProject = () => {
      store
        .dispatch("project/fetchProject", { id: router?.currentRoute.params.id })
        .then((response) => {
          projectData.value = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            projectData.value = undefined;
          }
        });
    }

    getProject()

    return {
      projectData,
      getProject,
    };
  },
};
</script>

<style></style>
