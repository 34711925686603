<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="projectLocal.avatar"
          :text="avatarText(projectLocal.code)"
          :variant="`light-${resolveProjectTypeVariant(projectLocal.code)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="d-flex align-items-center mb-1">
        {{ projectLocal.code }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">Changer</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Retirer</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div> -->
    </b-media>

    <!-- Project Info: Input Fields -->
    <b-form @submit.prevent="onSubmit" @reset.prevent="resetForm">
      <b-row class="mb-2">
        <!-- Field: Code -->
        <b-col cols="12" md="4">
          <b-form-group label="Code" label-for="code">
            <b-form-input id="code" v-model="projectLocal.code" disabled />
          </b-form-group>
        </b-col>

        <!-- Field: Customer -->
        <b-col cols="12" md="4">
          <b-form-group
              label="Client"
              label-for="customer"
            >
              <vue-autosuggest
                :suggestions="customers"
                v-model="projectLocal.customer"
                :input-props="inputProps"
                @selected="onSelectedCustomer"
                :limit="10"
                @input="onInputChange"
              >
                <template slot-scope="{ suggestion }">
                  <div class="d-flex align-items-center">
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.name }}
                      </b-card-text>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>
            </b-form-group>
        </b-col>

        <!-- Field: Lessor -->
        <b-col cols="12" md="4">
          <b-form-group label="Bailleur" label-for="lessor">
            <b-form-input id="lessor" v-model="projectLocal.details.lessor" />
          </b-form-group>
        </b-col>

        <!-- Field: Type -->
        <b-col cols="12" md="4">
          <b-form-group label="Type" label-for="project-type">
            <v-select
              v-model="projectLocal.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              :reduce="(type) => type.value"
              :clearable="false"
              input-id="project-type"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="project-status">
            <v-select
              v-model="projectLocal.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(status) => status.key"
              :clearable="false"
              input-id="project-status"
            />
          </b-form-group>
        </b-col>

        
        <!-- Field: Concerned Department -->
        <b-col cols="12" md="4">
          <b-form-group
            label="Département Concerné"
            label-for="concerned_department"
          >
            <v-select
              v-model="projectLocal.details.concerned_department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companyList()"
              :reduce="(o) => o.ref"
              :clearable="false"
              placeholder="Choisir le département concerné"
              input-id="status"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pas de résultat pour <em>{{ search }}</em
                  >.
                </template>
                <em v-else style="opacity: 0.5">Recherche un département Concerné...</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-row>
            <!-- Field: Deposit Date -->
            <b-col cols="12">
              <b-form-group label="Date de Dépôt" label-for="deposit_date">
                <flat-pickr
                  id="deposit_date"
                  v-model="projectLocal.details.deposit_date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Deposit Place -->
            <b-col cols="12">
              <b-form-group label="Lieu de Dépôt" label-for="deposit_place">
                <b-form-input
                  id="deposit_place"
                  v-model="projectLocal.details.deposit_place"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- Field: Brief -->
        <b-col cols="12" md="8">
          <b-form-group label="Description" label-for="project-description">
            <quill-editor
              id="quil-content"
              v-model="projectLocal.brief"
              :options="editorOption"
              class="border-bottom-0"
            />
            <div
              id="quill-toolbar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        type="submit"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small></b-spinner>
        Sauvegarder
      </b-button>
      <!-- <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Restaurer
      </b-button> -->
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardText,
  BSpinner,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, watch, onMounted } from "@vue/composition-api";
import useProjectsList from "../projects-list/useProjectsList";
import store from "@/store";
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardText,
    BSpinner,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
    flatPickr,
    quillEditor,
    VueAutosuggest,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter();
    const isLoading = ref(false);

    onMounted(() => {
      let props = ["customers", "countries", "status"];
      fetchProjectProperties(props);
    });

    const {
      companyList,
      fetchProjectProperties,
      resolveProjectTypeVariant,
      customersFilter,
      countryOptions,
      statusOptions,
      suggestLimit,

      onInputChange,
    } = useProjectsList();

    const typeOptions = [
      { label: "Étude", value: "étude" },
      { label: "Contrôle", value: "contrôle" },
      { label: "Étude et Contrôle", value: "étude et contrôle" },
    ];

    const inputProps = {
      id: "autosuggest__input",
      class: "form-control",
      placeholder: "Taper le nom du client",
    };

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Écrivez une description brieve du projet",
    };

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.projectData.avatar = base64;
      }
    );

    const onSelectedCustomer = (inputOption) => {
      Object.assign(projectLocal.value, { customer: inputOption.item.name });
    };

    const projectLocal = ref(JSON.parse(JSON.stringify(props.projectData)))

    const resetForm = () => {
      projectLocal.value = JSON.parse(JSON.stringify(props.projectData))
    }

    watch(projectLocal, (newProjectData) => {
      if(newProjectData != undefined)
        if(
          newProjectData.hasOwnProperty('customer') && (typeof newProjectData.customer === 'object')
        )
        newProjectData.customer = newProjectData.customer?.name
    }, { deep: true, immediate: true })

    watch(props.projectData, () => {
      resetForm()
    }, { deep: true })

    const onSubmit = () => {
      isLoading.value = true

      store.dispatch("project/updateProject", projectLocal.value).then((response) => {
        projectLocal.value = response.resource
        isLoading.value = false
        router.go(-1);
      })
      .catch(error => isLoading.value = false);
    }

    return {
      isLoading,
      resolveProjectTypeVariant,
      avatarText,

      typeOptions,
      statusOptions,
      countryOptions,
      inputProps,
      companyList,

      onInputChange,

      customers: customersFilter,
      suggestLimit,
      projectLocal,
      
      resetForm,
      onSubmit,
      onSelectedCustomer,

      // UI
      editorOption,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
